const SIGNATURE_EXIST = "SIGNATURE_EXIST";
const SIGNATURE_IMAGE = "SIGNATURE_IMAGE";
const SIGNATURE_SEND_PROCESS = "SIGNATURE_SEND_PROCESS";

export const setExist = (exist) => {
    return (dispatch) => {
        dispatch({
            type: SIGNATURE_EXIST,
            payload: exist
        })
    }
}

export const setImage = (image) => {
    return (dispatch) => {
        dispatch({
            type: SIGNATURE_IMAGE,
            payload: image
        })
    }
}

export const setProcess = (process) => {
    return (dispatch) => {
        dispatch({
            type: SIGNATURE_SEND_PROCESS,
            payload: process
        })
    }
}

