const initState ={
    close: false,
    close_banner: false,
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case "MODAL_CLOSE" : 
        return{
            ...state, 
            close: action.payload,
        }
        case "BANNER_CLOSE" :
        return{
            ...state, 
            close_banner: action.payload,
        }
        default :
        return state
    }
}

export default reducer;