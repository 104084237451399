export const initState = {
    support_tab: 0,

    search_keyword: null,

    notice_data: null,
    notice_id: null,
    notice_category: 0,

    faq_data: null,
    faq_category: 0,
    faq_category_list: [
        ["SUPPORT_FAQ_TAB1", 0],
        ["SUPPORT_FAQ_TAB2", 0],
        ["SUPPORT_FAQ_TAB3", 0],
        ["SUPPORT_FAQ_TAB4", 0],
        ["SUPPORT_FAQ_TAB5", 0],
        ["SUPPORT_FAQ_TAB6", 0],
        ["SUPPORT_FAQ_TAB7", 0]
    ]
};

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case "SUPPORT_TAB":
            return {
                ...state,
                support_tab: action.payload
            };

        case "SEARCH_KEYWORD":
            return {
                ...state,
                search_keyword: action.payload
            };

        case "NOTICE_DATA":
            return {
                ...state,
                notice_data: action.payload
            };
        case "NOTICE_ID":
            return {
                ...state,
                notice_id: action.payload
            };
        case "NOTICE_CATEGORY":
            return {
                ...state,
                notice_category: action.payload
            };

        case "FAQ_DATA":
            return {
                ...state,
                faq_data: action.payload
            };
        case "FAQ_CATEGORY":
            return {
                ...state,
                faq_category: action.payload
            };
        case "FAQ_CATEGORY_LIST":
            return {
                ...state,
                faq_category_list: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
