import { bindActionCreators } from "redux";
// import actions
import * as noticeModal from "./actions/notice_modal";
import * as support from "./actions/support";
import * as signature from "./actions/signature";
import * as publicData from "./actions/public_data";
import {store} from "src/redux/store";
const {dispatch} = store;
// export actions
export const NoticeModalActions = bindActionCreators(noticeModal, dispatch);
export const SupportActions = bindActionCreators(support, dispatch);
export const SignatureActions = bindActionCreators(signature, dispatch);
export const PublicDataActions = bindActionCreators(publicData, dispatch);