
const initState ={
    exist: false,
    image: '',
    process: 0,
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case "SIGNATURE_EXIST" : 
        return{
            ...state, 
            exist: action.payload,
        }
        case "SIGNATURE_IMAGE" : 
        return{
            ...state, 
            image: action.payload,
        }
        case "SIGNATURE_SEND_PROCESS" : 
        return{
            ...state, 
            process: action.payload,
        }
        default :
        return state
    }
}

export default reducer;