const MODAL_CLOSE = 'MODAL_CLOSE';
const BANNER_CLOSE = 'BANNER_CLOSE';

export const setClose = (boolean) => {
    return (dispatch) => {
        dispatch({
            type: MODAL_CLOSE,
            payload: boolean
        })
    }
}

export const setCloseBanner = (boolean) => {
    return (dispatch) => {
        dispatch({
            type: BANNER_CLOSE,
            payload: boolean
        })
    }
}

