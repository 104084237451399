import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useInterval } from "react-use";
import Cookies from "js-cookie";
import { v4 } from "uuid";
import axios from "axios";

const loadScript = () => {
    var w = window;
    if (w.ChannelIO) {
        return (window.console.error || window.console.log || function () {})("ChannelIO script included twice.");
    }
    var ch = function () {
        ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
        ch.q.push(args);
    };
    w.ChannelIO = ch;

    function l() {
        if (w.ChannelIOInitialized) {
            return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
        s.charset = "UTF-8";
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
    }

    if (document.readyState === "complete") {
        l();
    } else if (window.attachEvent) {
        window.attachEvent("onload", l);
    } else {
        window.addEventListener("DOMContentLoaded", l, false);
        window.addEventListener("load", l, false);
    }
};

// loadScript();
export const useChannelIO = () => {
    const [config, setConfig] = useState();
    const [channelIOLoaded, setChannelIOLoaded] = useState(false);
    useInterval(
        () => {
            if (!window.ChannelIO) {
                loadScript();
            } else {
                setChannelIOLoaded(true);
            }
        },
        channelIOLoaded ? null : 1000
    );

    const getConfig = useCallback(async () => {
        const defaultConfig = {};

        getChannelIOConfig({ memberId: getMemberId() }).then((_config) => {
            const config = {
                ...defaultConfig,
                ..._config
            };
            setConfig(config);
        });
    }, []);

    useEffect(() => {
        if (!window.ChannelIO) return;
        getConfig();
    }, [getConfig,channelIOLoaded]);

    useEffect(() => {
        if (!window.ChannelIO) return;
        if (!config) return;
        window.ChannelIO("boot", config);
        return () => window.ChannelIO("shutdown");
    }, [config]);
};

export const showChannelTalk = () => {
    window.ChannelIO("showMessenger");
};

const getChannelIOConfig = ({ memberId }) => {
    return axios
        .get("users/getChannelIOConfig", {
            baseURL: process.env.GATSBY_DONUE_ACCOUNT_API_HOST,
            headers: {
                session: Cookies.get("session")
            },
            params: { memberId }
        })
        .then((res) => {
            return res.data?.payload;
        });
};

const getMemberId = () => {
    let memberId = Cookies.get("donue-ch-memberId");

    if (!memberId) {
        memberId = v4();
        Cookies.set("donue-ch-memberId", memberId, { expires: 1 });
    } else {
        Cookies.set("donue-ch-memberId", memberId, { expires: 1 });
    }

    return memberId;
};
