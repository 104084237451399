import { combineReducers } from "redux";
import NoticeModal from './notice_modal';
import Support from './support';
import Signature from './signature';
import PublicData from './public_data';

const reducers = combineReducers({
    noticeModal: NoticeModal,
    support: Support,
    signature: Signature,
    publicData: PublicData,
});

export default reducers;
  