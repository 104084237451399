const SUPPORT_TAB = "SUPPORT_TAB";

const SEARCH_KEYWORD = "SEARCH_KEYWORD";

const NOTICE_DATA = "NOTICE_DATA";
const NOTICE_ID = "NOTICE_ID";
const NOTICE_CATEGORY = "NOTICE_CATEGORY";

const FAQ_DATA = "FAQ_DATA";
const FAQ_CATEGORY = "FAQ_CATEGORY";
const FAQ_CATEGORY_LIST = "FAQ_CATEGORY_LIST";

export const setSupportTab = (tab) => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_TAB,
            payload: tab
        })
    }
}

export const setKeyword = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_KEYWORD,
            payload: keyword
        })
    }
}

export const setNoticeData = (data) => {
    return (dispatch) => {
        dispatch({
            type: NOTICE_DATA,
            payload: data
        })
    }
}
export const setNoticeId = (id) => {
    return (dispatch) => {
        dispatch({
            type: NOTICE_ID,
            payload: id
        })
    }
}
export const setNoticeCategory = (cat) => {
    return (dispatch) => {
        dispatch({
            type: NOTICE_CATEGORY,
            payload: cat
        })
    }
}

export const setFaqData = (data) => {
    return (dispatch) => {
        dispatch({
            type: FAQ_DATA,
            payload: data
        })
    }
}

export const setFaqCategory = (cat) => {
    return (dispatch) => {
        dispatch({
            type: FAQ_CATEGORY,
            payload: cat
        })
    }
}

export const setFaqCategoryList = (cat) => {
    return (dispatch) => {
        dispatch({
            type: FAQ_CATEGORY_LIST,
            payload: cat
        })
    }
}