import { useEffect } from "react";
import axios from "axios";
import { PublicDataActions } from "redux/actions";

export default function useOrganizeLinks(language) {
    useEffect(() => {
        axios
            .get(`https://donue.s3.ap-northeast-2.amazonaws.com/static/url.json`, {
                headers: {
                    "Content-type": `application/json`
                }
            })
            .then((res) => {
                PublicDataActions.setServiceGuideLink(res.data[language].서비스가이드);
                PublicDataActions.serServiceIntrodueLink(res.data[language].서비스소개서);
                PublicDataActions.setChanneltalk(res.data[language].채널톡);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [language]);
}
