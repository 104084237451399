import { configureStore } from "@reduxjs/toolkit"
import thunk from "redux-thunk"
import reducers from "./reducers"
import { persistStore, persistReducer } from "redux-persist"
import storage from 'redux-persist/lib/storage';

const isDev = process.env.NODE_ENV !== 'production' ? true : false;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["publicData", "support"],
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer:persistedReducer,
    middleware: [thunk],
    devTools: isDev
})

export const persistor = persistStore(store);