const initState = {
    service_guide_link: "",
    service_introduce_link: "",
    channeltalk: "",
    close_while_a_day: false,
    banner_close_forever: false
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case "SERVICE_GUIDE_LINK":
            return {
                ...state,
                service_guide_link: action.payload
            };
        case "SERVICE_INTRODUCE_LINK":
            return {
                ...state,
                service_introduce_link: action.payload
            };
        case "CHANNELTALK":
            return {
                ...state,
                channeltalk: action.payload
            };
        case "MODAL_CLOSE_WHILE_A_DAY":
            return {
                ...state,
                close_while_a_day: action.payload
            };
        case "BANNER_CLOSE_FOREVER":
            return {
                ...state,
                banner_close_forever: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
