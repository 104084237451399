const SERVICE_GUIDE_LINK = "SERVICE_GUIDE_LINK";
const SERVICE_INTRODUCE_LINK = "SERVICE_INTRODUCE_LINK";
const CHANNELTALK = 'CHANNELTALK';
const MODAL_CLOSE_WHILE_A_DAY = "MODAL_CLOSE_WHILE_A_DAY";
const BANNER_CLOSE_FOREVER = "BANNER_CLOSE_FOREVER";

export const setServiceGuideLink = (link) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_GUIDE_LINK,
            payload: link
        })
    }
}

export const serServiceIntrodueLink = (link) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_INTRODUCE_LINK,
            payload: link
        })
    }
}

export const setChanneltalk = (link) => {
    return (dispatch) => {
        dispatch({
            type: CHANNELTALK,
            payload: link
        })
    }
}

export const setCloseWhileADay = (boolean) => {
    return (dispatch) => {
        dispatch({
            type: MODAL_CLOSE_WHILE_A_DAY,
            payload: boolean
        })
    }
}

export const setCloseBannerForever = (boolean) => {
    return (dispatch) => {
        dispatch({
            type: BANNER_CLOSE_FOREVER,
            payload: boolean
        })
    }
}

